@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  transition: background-color 0.5s;
}

.TableLikeGroup-progress::-webkit-progress-value {
  transition: all 1s;
}

.btn-square,
.input,
.btn {
  --rounded-btn: 0.5rem !important;
}

@layer components {
  .rounded-corners {
    @apply rounded-2xl border-collapse overflow-hidden;
  }
}
